import React from 'react'
import classes from './Actions.css'
import Action from './Action/Action'

    


const actions = () => {
    return (
        <div className={classes.ActionsContainer}>
            <Action icon="hands-helping">
                Individuāla pieeja katram klientam. Lai izvērtētu Jūsu vēlmes, sazināsimies pa tālruni, e-pasta vēstulēs vai klātienē.
            </Action>       
            
            <Action /* icon="user-alt" */>
                Piedāvāsim labākos iespējamos risinājumus.
            </Action>  
            
            <Action icon="clock">
                Realizēsim Jūsu vēlmes iespējami īsā laikā un vislabākajā kvalitātē
            </Action>
        </div>
    )
}

export default actions