import React, { Component } from 'react'
import classes from './HeaderDetails.css'

let textMessage1 = "Jums ir nepieciešams ūdens filtrs vai ražošanas procesu automatizācijas pakalpojumi?";
let textMessage2 = "Mēs izvērtēsim Jūsu vēlmes, apsekosim objektu un piedāvāsim labākos iespējamos risinājumus";

class HeaderDetails extends Component {
    state = {
        showFirstMessage: true
    }

    switchTimer = null;

    componentDidMount() {
        this.switchTimer = setInterval(this.timedTextSwitch, 10000);
    }

    timedTextSwitch = () => {
        this.setState({ showFirstMessage: !this.state.showFirstMessage });
    }

    setFirstMessageActive(active) {
        if (this.switchTimer) clearInterval(this.switchTimer);
        this.setState({ showFirstMessage: active });
        this.switchTimer = setInterval(this.timedTextSwitch, 10000);
    }

    render() {
        let firstMessageClasses = classes.TextContainer;
        let secondMessageClasses = classes.TextContainer;

        if (this.state.showFirstMessage) {
            firstMessageClasses = [firstMessageClasses, classes.Active].join(" ");
        } else {
            secondMessageClasses = [secondMessageClasses, classes.Active].join(" ");
        }

        return (
            <>
                <div className={classes.HeaderMessage}>
                    <div className={firstMessageClasses}>
                        {textMessage1}
                    </div>
                    <div className={secondMessageClasses}>
                        {textMessage2}
                    </div>
                </div>

                <div className={classes.HeaderButtonContainer}>
                    <div className={classes.ButtonContainer}>
                        <div className={classes.ButtonSection}>
                            <div
                                className={this.state.showFirstMessage ? classes.Circle + ' ' + classes.Active : classes.Circle}
                                onClick={() => { this.setFirstMessageActive(true) }}
                            ></div>
                            <div
                                className={!this.state.showFirstMessage ? classes.Circle + ' ' + classes.Active : classes.Circle}
                                onClick={() => { this.setFirstMessageActive(false) }}
                            ></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default HeaderDetails