import React from 'react'
import classes from './Menu.css'

let menuObj = [
    { id: "#par_mums", name: "PAR MUMS" },
    { id: "#pakalpojumi", name: "PAKALPOJUMI" },
    { id: "#realizetie_projekti", name: "REALIZĒTIE PROJEKTI" },
    { id: "#kontakti", name: "KONTAKTI" },
];

const menu = () => (
    <div className={classes.Menu}>
        {menuObj.map(menuItem => {
            return <a key={menuItem.id} className={classes.MenuItem} href={menuItem.id}>{menuItem.name}</a>
        })}
    </div>
)


export default menu