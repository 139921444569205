import React from 'react'
import classes from './Container.css'
import About from './About/About'
import Actions from './About/Actions/Actions'
import Services from './Services/Services'
import Projects from './Projects/Projects'

const container = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <About />
                <Actions />
                <Services />
                <Projects />
            </div>
        </div>
    )
}

export default container