import React, { Component } from 'react'
import classes from './Services.css'
import Service from './Service/Service'
import axios from 'axios'

let dataPromise = axios.get('/data.json');

class Services extends Component {
    state = {
        serviceList: [],
        err: ''
    }
    componentDidMount = () => {
        dataPromise.then(response => {
            if (response.data && response.data['serviceList']) {
                this.setState({ serviceList: response.data.serviceList, err: '' });
            } else {
                this.setState({ err: 'Error in loading services ...' });
            }
        }).catch(err => {
            this.setState({ err: 'Error in loading services: ' + err });
        });
    }

    render() {
        let serviceContainer = null;

        if (this.state.err) {
            serviceContainer = <div className={classes.Error}> {this.state.err} </div>
        } else {
            serviceContainer = (
                <div className={classes.ServicesListContainer}>
                    {this.state.serviceList.map((service, index) => {
                        return <Service index={index} key={'service_' + index} icon={service.icon}>{service.name}</Service>
                    })}
                </div>
            );
        }

        return (
            <div className={classes.ServicesContainer}>
                <div id="pakalpojumi" className={classes.Title}>PAKALPOJUMI</div>
                <div className={classes.ServicesInfo}>Mēs piedavājam plašu pakalpojumu spektru</div>
                {serviceContainer}
            </div>
        );
    }
}

export default Services;