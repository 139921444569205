import React from 'react'
import classes from './About.css'

const about = () => {
    return (
        <div className={classes.AboutContainer}>
            <div id="par_mums" className={classes.Title}>PAR MUMS</div>
            <div className={classes.AboutInfo}> „Ūdens Enerģija” darbības sfēra ir ražošanas procesu automatizācija efektivitātes uzlabošanai. Veicam visus nepieciešamos elektroinstalācijas un montāžas darbus. Piedāvājam ūdens filtru uzstādīšanu dzeramā ūdens kvalitātes uzlabošanai.</div>
        </div>
    )
}

export default about