import React from 'react'
import classes from './Contacts.css'

const contacts = () => (
    <div className={classes.ContactsContainer}>
        <div id="kontakti" className={classes.Title}>KONTAKTI</div>
        <div className={classes.InfoContainer}>
            <div className={classes.InfoBox}>
                <div className={classes.InfoBoxTitle}>+371 286 168 91</div>
                <div className={classes.Email}>kaspars@udensenergija.lv</div>
            </div>

            <div className={classes.InfoBox}>
                <div className={classes.InfoBoxTitle}>JURIDISKĀ ADRESE</div>
                <div className={classes.Text24}>Budeskalnu iela 15E</div>
                <div className={classes.Text24}>Salaspils, LV-2169</div>
            </div>
        </div>
    </div>
)


export default contacts