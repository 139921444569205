import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faMobileAlt, faHandsHelping, faClock, faUserAlt, faTint, faBolt, faVectorSquare, faHammer, faDesktop, faFillDrip } from '@fortawesome/free-solid-svg-icons';
import Header from './Components/Header/Header'
import Container from './Components/Container/Container'
import Footer from './Components/Footer/Contacts'

library.add(faEnvelope, faMobileAlt, faHandsHelping, faClock, faUserAlt, faTint, faBolt, faVectorSquare, faHammer, faDesktop, faFillDrip);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header/>
        <Container/>
        <Footer/>
      </div>
    );
  }
}

export default App;