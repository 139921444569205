import React from 'react'
import classes from './Header.css'
import HeaderTop from './HeaderTop/HeaderTop'
import HeaderDetails from './HeaderDetails/HeaderDetails'

const header = () => (
    <div className={classes.HeaderContainer}>
        <div className={classes.HeaderContent}>
            <HeaderTop />
            <HeaderDetails />
        </div>
    </div>
)


export default header