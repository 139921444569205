import React from 'react'
import classes from './ImgViewer.css'

const imgViewer = (props) => {
  let classList = classes.BackDrop;
  if (props.active) {
    classList = [classList, classes.isActive].join(" ");
  }

  return (
    <div className={classList} onClick={props.onClose}>
      {props.children}
      <div className={classes.Overlay}/>
    </div>
  )
};

export default imgViewer;