import React from 'react'
import classes from './Service.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const service = (props) => {

    let iconTemplate = props.icon ? <FontAwesomeIcon icon={props.icon} /> : null;
    let boxClass = props.index < 5 ? classes.ServiceContainer : [classes.ServiceContainer, classes.ServiceBoxDarker].join(" ");

    return (
        <div className={boxClass}>
            <div className={classes.InnerBox}>
                {iconTemplate}
                <div>{props.children}</div>
            </div>
        </div>
    )
}

export default service