import React from 'react'
import classes from './Action.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultIcon from '../../../../../Assets/icon_custom.png' 

const action = (props) => {

    const icon = props.icon?<FontAwesomeIcon icon={props.icon}/> : <img alt="icon" src={DefaultIcon}></img>;

    return (
        <div className={classes.ActionContainer}>
            {icon}
            <div>{props.children}</div>
        </div>
    )
}

export default action