import React from 'react'
import classes from './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let contactObj = {
    email: "kaspars@udensenergija.lv",
    phone: "+371 286 168 91"
}

const contact = () => (
    <div className={classes.HeaderContact}>
        <div> <FontAwesomeIcon icon="envelope" /> {contactObj.email}</div>
        <div> <FontAwesomeIcon icon="mobile-alt" /> {contactObj.phone}</div>
    </div>
)


export default contact