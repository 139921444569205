import React from 'react'
import classes from './HeaderTop.css'

import Logo from '../../../Assets/Logo.png'
import Contact from './ContactDetails/Contact'
import Menu from './Menu/Menu'

const headerTop = () => (
    <div className={classes.HeaderTop}>
        <img alt="Ūdens enerģija" src={Logo} />
        <div className={classes.HeaderTopContainer}>
            <Contact />
            <Menu />
        </div>
    </div>
)


export default headerTop