import React, { Component } from 'react'
import classes from './Projects.css'
import Slider from 'react-slick'
import ImgViewer from './ImgViewer/ImgViewer'
import axios from 'axios'

let imagePromise = axios.get('/images.json');

class Projects extends Component {
    state = {
        active: false,
        selectedImg: 0,
        listOfImages: [],
        imageList: [],
        err: 'Loading...'
    }

    changeState = (selectedImg) => {
        this.setState({
            active: !this.state.active,
            selectedImg: selectedImg
        });
    }

    closePopup = () => {
        this.setState({
            active: false
        });
    }

    componentDidMount = () => {
        imagePromise.then(response => {
            console.log(response)
            if (response.data && response.data['imageList']) {
                this.setState({ imageList: response.data.imageList, err: '' });
            } else {
                this.setState({ err: 'Error in loading images ...' });
            }
        }).catch(err => {
            this.setState({ err: 'Error in loading images: ' + err });
        });
    }

    render() {
        const settings = {
            dots: true,
            lazyLoad: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0
        };

        const images = this.state.imageList;

        if(images.length === 0) return <>{this.state.err}</>

        const selectedImg = images[this.state.selectedImg]
        let selectedImgClasses = [classes.ImgBig]
        if(selectedImg.rotation === "90"){
            selectedImgClasses.push(classes.Rot90)
        } else if(selectedImg.rotation === "180") {
            selectedImgClasses.push(classes.Rot180)
        } else if(selectedImg.rotation === "270"){
            selectedImgClasses.push(classes.Rot270)
        }
        selectedImgClasses = selectedImgClasses.join(" ")

        return (
            <div className={classes.ProjectsContainer}>
                <div id="realizetie_projekti" className={classes.Title}>REALIZĒTIE PROJEKTI</div>

                <div className={classes.ProjectsListContainer}>
                    <Slider {...settings}>
                        {
                            images.map((img , index) => {
                                let imageClasses = [classes.Img]
                                if(img.rotation === "90"){
                                    imageClasses.push(classes.Rot90)
                                } else if(img.rotation === "180") {
                                    imageClasses.push(classes.Rot180)
                                } else if(img.rotation === "270"){
                                    imageClasses.push(classes.Rot270)
                                }

                                imageClasses = imageClasses.join(" ")

                                return (
                                    <div key={'scroll' + index}>
                                        <img onClick={() => { this.changeState(index) }} className={imageClasses} alt='project img' src={`/images/${img.name}`} />
                                    </div>)

                            })
                        }
                    </Slider>
                </div>
                <ImgViewer active={this.state.active} onClose={this.closePopup}>
                    <img className={selectedImgClasses} alt='project img' src={`/images/${selectedImg.name}`} />
                </ImgViewer>
            </div>
        )
    }
}

export default Projects;